import SousMenu from './SousMenu';
import React, { Component } from 'react';
import { Row, Col } from 'antd';
import Ad from '../commun/adSense';
import border from '../../images/border.png';
import Tableau from '../commun/Tableau';

import { Helmet } from 'react-helmet';


export default class Dictee extends Component
{

  constructor()
  {
    super();
  
  }
 
    render()
    {
        return(
        <div>
     
           <Helmet>
           <title>Les tests d'orthographe : les dictées </title>
          <meta name="description" content="Des dictées audio gratuites en ligne pour tous les niveaux (facile ou difficile) et pour tous les âges (enfants ou adultes). La correction de la dictée est automatique." />
        </Helmet>
        <div className="titre centre">Tests d'orthographe et de grammaire</div>
      <div className='centre'>evalquiz : le site numéro 1 du divertissement intelligent</div>
      <h2 className="titre centre">Les dictées</h2>
   <Row gutter={8} className="grandEspaceHaut">
          <Col md={8}>
            <h1>Les dictées</h1>
            <p>Choisissez votre dictée en fonction de la difficulté.</p>
            <p> La correction est automatique. Tapez le texte normalement et la correction s'effectuera automatiquement.</p>
            <p>Vous pouvez écouter la dictée autant de fois que vous le désirez. Il suffit de revenir en arrière avec le lecteur audio.</p>
            <p>La correction prend en compte la ponctuation et les espaces entre les éléments de ponctuation.</p>
            <p>Réviser <a target="_blank" rel="noopener noreferrer" href="http://www.la-ponctuation.com/generalites.html">les espaces et la ponctuation</a>.</p>
            <p>Si vous réalisez un sans-faute, votre prénom sera inscrit au  tableau d'honneur des dictées.</p>
         
          </Col>
        <Col md={10}>
      <SousMenu type='dictee' titre='Les meilleures dictées'></SousMenu>
        </Col>
        </Row>
        <Ad></Ad>
        <Row>
        <Tableau type="dictee"></Tableau>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
        <Row gutter={8} className="espaceHaut">
          <Col md={8}>
            <h1>Connaitre l'orthographe des mots</h1>
            <p>Testez-vous en écrivant correctement les mots sans faute d'orthographe.</p>
            <p>Les verbes sont toujours à l'infinitif et les mots au singulier.</p>
            <p>Il y a toujours 5 mots à orthographier. Vous pouvez les réécouter autant de fois que vous le désirez.</p>
            <p>Si vous réalisez un sans-faute, votre prénom sera inscrit au tableau d'honneur de l'orthographe.</p>
           
          </Col>
        <Col md={10}>
        <SousMenu type="orthographemot" titre="Écrire des mots sans faute"></SousMenu>
        </Col>
        </Row>
        <Row>
        <Tableau type="orthographemot"></Tableau>
        </Row>
        <div className="centre grandEspaceHaut"><img src={border} alt="bordure"  width="100" height="41"></img></div>
     
        </div>)
    }

}
