import { HomeFilled} from '@ant-design/icons';
const itemsMenu = [
    {
      label: (<a href="https://evalquiz.com"> evalquiz</a>
      ),
      key: 'evalquiz',
      icon: <HomeFilled />,
    },
    {
        label: ( <a href="/">
        Orthographe
        </a>
        ),
        key: 'orthographe'
      },
      {
        label: (  <a href="https://concours.evalquiz.com/">
        Challenge
       </a>
        ),
        key: 'challenge'
      },
      {
        label: ( <a href="https://evalquiz.com/tests/culture">
        Culture
       </a>
        ),
        key: 'culture'
      },
      {
        label: ( <a href="https://quiz-royal.evalquiz.com">
        QuizRoyal
       </a>
        ),
        key: 'quizRoyal'
      },
      {
        label: ( <a href="https://evalquiz.com/tests/math">
        Calcul
       </a>
        ),
        key: 'calcul'
      },
      {
        label: (   <a href="https://evalquiz.com/tests/logique">
        QI
       </a>
        ),
        key: 'logique'
      }, 
       {
        label: (  <a href=" https://anglais.evalquiz.com">
            Anglais
            </a>
        ),
        key: 'anglais'
      },
      {
        label: (  <a href="https://cerebral.evalquiz.com">
        Entrainement cérébral
       </a>
        ),
        key: 'cerebral'
      },

  ];

  export {itemsMenu}