let donneesMots =
    [{
        id: 40,
        titre: "5 mots très simples",
        explication: "Retrouver 5 mots simples à partir des définitions du dictionnaire.",
        liste: [
            {
                mot: "hotte",
                definition: "Grand panier qui se fixe sur le dos au moyen de bretelles et qui sert à transporter divers objets.",
                ordre: [0, 2, 3, 1, 4]
            },
            {
                mot: "adieu",
                definition: "Formule de salutation adressée à quelqu'un qu'on quitte pour une longue période, ou même définitivement.",
                ordre: [2, 0, 4, 1, 3]
            },
            {
                mot: "apnée",
                definition: "Arrêt de la respiration de durée variable, sans arrêt cardiaque.",
                ordre: [3, 1, 4, 0, 2]
            },
            {
                mot: "flute",
                definition: "Instrument de musique à air, à tuyau ouvert dans lequel le son est produit par un souffle dirigé sur une arête où il se brise, mettant ainsi en vibration l'air du tuyau.",
                ordre: [4, 1, 3, 2, 0]
            },
            {
                mot: "fosse",
                definition: "Trou creusé en terre pour y inhumer un mort.",
                ordre: [2, 1, 3, 4, 0]
            }
        ]
    },
    {
        id: 41,
        titre: "5 mots simples de 6 lettres",
        liste: [
            {
                mot: "boucle",
                definition: "Anneau ou rectangle de métal avec traverse portant en général un ou plusieurs ardillons, qui sert à assujettir les deux extrémités d'une courroie, d'une ceinture, etc.",
                ordre: [5, 2, 0, 4, 1, 3]
            },
            {
                mot: "vessie",
                definition: "Poche du petit bassin où s'accumule entre deux mictions l'urine amenée par les uretères, et communiquant avec l'extérieur par le canal de l'urètre.",
                ordre: [2, 5, 0, 4, 1, 3]
            },
            {
                mot: "zénith",
                definition: "Point de la sphère céleste représentatif de la verticale ascendante, en un lieu donné.",
                ordre: [3, 1, 4, 5, 0, 2]
            },
            {
                mot: "tiroir",
                definition: "Compartiment sans couvercle emboîté dans un meuble et qu'on peut tirer à volonté.",
                ordre: [4, 5, 1, 3, 2, 0]
            },
            {
                mot: "sieste",
                definition: "Repos, petit somme, après le repas de midi.",
                ordre: [2, 1, 3, 4, 5, 0]
            },
        ]
    },
    {
        id: 42,
        titre: "5 mots de 7 lettres",
        liste: [
            {
                mot: "babiole",
                definition: "Petit objet de peu de valeur.",
                ordre: [5, 2, 0, 6, 4, 1, 3]
            },
            {
                mot: "bardage",
                definition: "Revêtement protecteur mince, à dilatation libre, de l'ossature ou des murs extérieurs d'un bâtiment.",
                ordre: [2, 5, 6, 0, 4, 1, 3]
            },
            {
                mot: "torrent",
                definition: "Cours d'eau de montagne, rapide et irrégulier, de faible longueur, plus ou moins à sec entre des crues violentes et brusques.",
                ordre: [3, 1, 4, 5, 0, 2, 6]
            },
            {
                mot: "trémolo",
                definition: "Tremblement de la voix indiquant une forte émotion, souvent feinte ou exagérée.",
                ordre: [6, 4, 5, 1, 3, 2, 0]
            },
            {
                mot: "tringle",
                definition: "Barre métallique ou de bois destinée à suspendre un rideau, une draperie, etc.",
                ordre: [2, 6, 1, 3, 4, 5, 0]
            }
        ]
    },
    {
        id: 43,
        titre: "5 mots difficiles",
        liste: [
            {
                mot: "logorrhée",
                definition: "Flot de paroles rapide et incoercible, généralement d'origine pathologique (ex: troubles bipolaires).",
                ordre: [5, 2, 8, 0, 6, 4, 7, 1, 3]
            },
            {
                mot: "esperluette",
                definition: "Logogramme &, résultant de la ligature du e et du t par les copistes médiévaux.",
                ordre: [10, 9, 5, 2, 8, 0, 6, 4, 7, 1, 3]
            },
            {
                mot: "procrastination",
                definition: "Action de reporter, de remettre à plus tard ce qui pourrait être fait le moment même.",
                ordre: [10, 12, 9, 5, 2, 8, 11, 0, 6, 4, 13, 7, 1, 3, 14]
            },
            {
                mot: "élucubration",
                definition: "Production déraisonnable, absurde, issue de recherches laborieuses ; divagation, extravagance.",
                ordre: [10, 9, 4, 2, 11, 8, 0, 6, 5, 7, 1, 3]
            },
            {
                mot: "psychotrope",
                definition: "Substance qui agit sur le psychisme, sur l'état du système nerveux central et y induit des modifications de la perception, des sensations, de l'humeur, de la conscience ou d'autres fonctions psychologiques et comportementales.",
                ordre: [3, 1, 10, 5, 9, 2, 8, 0, 6, 4, 7]
            }
        ]
    },
    {
        id: 44,
        titre: "Les nouveaux mots du dictionnaire 2020",
        liste: [
            {
                mot: "millenial",
                definition: "Personne devenue adulte aux environs de l'an 2000.",
                ordre: [5, 2, 8, 0, 6, 4, 7, 1, 3]
            },
            {
                mot: "coworking",
                definition: "Anglicisme qui désigne une organisation de travail regroupant plusieurs travailleurs indépendants dans un même lieu.",
                ordre: [3, 1, 8, 7, 6, 4, 0, 2, 5]
            },
            {
                mot: "cryptomonnaie",
                definition: "Monnaie numérique indépendante des réseaux bancaires et liée à un système de cryptage.",
                ordre: [10, 12, 9, 5, 2, 8, 11, 0, 6, 4, 7, 1, 3]
            },
            {
                mot: "antispécisme",
                definition: "Vision du monde qui récuse la notion de hiérarchie entre les espèces animales et la supériorité de l'être humain sur les animaux.",
                ordre: [10, 9, 4, 2, 11, 8, 0, 6, 5, 7, 1, 3]
            },
            {
                mot: "fachosphère",
                definition: "Ensemble des partis politiques de la mouvance fasciste et, plus largement, d'extrême droite.",
                ordre: [3, 7, 10, 5, 9, 2, 8, 0, 6, 4, 1]
            }
        ]
    },
    {
        id: 49,
        titre: "Les nouveaux mots du dictionnaire Larousse 2021",
        liste: [
            {
                mot: "antivax",
                definition: "Se dit d’un mouvement d’opinion marqué par une opposition à la vaccination en général.",
                ordre: [5, 2, 0, 6, 4, 1, 3]
            },
            {
                mot: "dégagisme",
                definition: "Attitude d’insoumission et de rejet prônant l’éviction des détenteurs du pouvoir.",
                ordre: [3, 5, 0, 7, 6, 4, 8, 2, 1]
            },
            {
                mot: "frugalisme",
                definition: "Mode de vie consistant à vivre en dessous de ses moyens et à épargner afin de quitter la vie active bien avant l’âge de la retraite.",
                ordre: [3, 9, 5, 2, 8, 0, 6, 4, 7, 1, 10]
            },
            {
                mot: "féminicide",
                definition: "Meurtre d’une femme ou d’une jeune fille, en raison de son appartenance au sexe féminin.",
                ordre: [9, 4, 2, 8, 3, 6, 5, 7, 1, 0]
            },
            {
                mot: "hygge",
                definition: "Sentiment de bien-être et de sérénité caractéristique de l’art de vivre danois, fondé sur les plaisirs authentiques du quotidien.",
                ordre: [2, 0, 3, 4, 1]
            }
        ]
    }
    ,
    {
        id: 50,
        titre: "Les nouveaux mots du dictionnaire Robert 2021",
        liste: [
            {
                mot: "cluster",
                definition: "Groupement d'un petit nombre d'objets, foyer épidémique",
                ordre: [5, 2, 3, 6, 4, 1, 0]
            },
            {
                mot: "pansexuel",
                definition: "Orientation sexuelle caractérisant les individus qui peuvent être attirés par un individu de n’importe quel sexe ou genre.",
                ordre: [3, 2, 4, 7, 6, 0, 8, 5, 1]
            },
            {
                mot: "écouvillon",
                definition: "Bâtonnet muni d'une petite brosse ou d'une matière absorbante pour effectuer des prélèvements.",
                ordre: [1, 9, 5, 2, 8, 0, 6, 4, 7, 3]
            },
            {
                mot: "polyamour",
                definition: "Multiple relations amoureuses avec le consentement éclairé de toutes les personnes concernées.",
                ordre: [4, 2, 8, 3, 6, 5, 7, 1, 0]
            },
            {
                mot: "veggie",
                definition: "Anglicisme de végétarien.",
                ordre: [5, 2, 0, 3, 4, 1]
            }
        ]
    }
    ,
    {
        id: 51,
        titre: "Les nouveaux mots du dictionnaire Larousse 2022",
        liste: [
            {
                mot: "émoji",
                definition: " Terme issu du japonais. Petite image utilisée dans un message électronique pour exprimer une émotion.",
                ordre: [2, 3, 4, 1, 0]
            },
            {
                mot: "mocktail",
                definition: "Ce terme, combinaison de mock (faux en anglais) et cocktail, désigne les mélanges sans alcool.",
                ordre: [0, 2, 4, 7, 6, 3, 5, 1]
            },
            {
                mot: "quatorzaine",
                definition: "Quarantaine de 14 jours",
                ordre: [10, 1, 9, 5, 2, 8, 0, 6, 4, 7, 3]
            },
            {
                mot: "asymptomatique",
                definition: "Adjectif. Se dit d'une maladie, d'un trouble qui ne s'accompagne pas de symptômes. On parle de porteur sain.",
                ordre: [13,4, 2, 8,10, 3, 6, 5, 7,9, 1, 0,11, 12]
            },
            {
                mot: "racisé",
                definition: "Adjectif désignant une personne touchée par le racisme, la discrimination.  Concept utilisé par les militants pour désigner les non-blancs dans leur ensemble.",
                ordre: [5, 2, 0, 3, 4, 1]
            }
        ]
    }
    ,
    {
        id: 45,
        titre: "Les mots provenant de l'anglais",
        liste: [
            {
                mot: "spoiler",
                definition: "Terme employé pour désigner un document ou un texte qui dévoile tout ou une partie de l'intrigue d'une œuvre (livre, film, jeu vidéo).",
                ordre: [2, 0, 6, 4, 5, 1, 3]
            },
            {
                mot: "snipper",
                definition: "Tireur embusqué ou franc-tireur.",
                ordre: [6, 3, 1, 4, 0, 2, 5]
            },
            {
                mot: "bankable",
                definition: " Se dit d'un acteur ou d'un personnage dont la présence suffit à faire le succès d'un film.",
                ordre: [2, 7, 6, 0, 4, 5, 1, 3]
            },
            {
                mot: "pitch",
                definition: "Résumé de l'intrigue d'un roman ou d'un film.",
                ordre: [2, 0, 4, 1, 3]
            },
            {
                mot: "backstage",
                definition: "Coulisses, loges d'une salle de théâtre ou de concert.",
                ordre: [8, 2, 6, 7, 0, 4, 3, 1, 5]
            }
        ]
    }  ,
    {
        id: 46,
        titre: "Entraînement aux mots croisés difficiles",
        liste: [
            {
                mot: "escalier",
                definition: "Tourne dans sa cage",
                ordre: [0, 1, 6,4, 5, 3, 2, 7]
            },
            {
                mot: "orsay",
                definition: "Gare à la peinture",
                ordre: [0, 1, 2, 4, 3]
            },
            {
                mot: "amnésique",
                definition: "N'a vraiment rien à regretter ",
                ordre: [2, 7, 6, 0, 4, 5, 1, 3, 8]
            },
            {
                mot: "cécité",
                definition: "Point de vue",
                ordre: [0,2,5, 4, 1, 3]
            },
            {
                mot: "essuie-glace",
                definition: "Collectionneur de papillons",
                ordre: [0,8, 2, 6, 7, 9,10,11, 4, 3, 1, 5]
            }
        ]
    },
    {
        id: 47,
        titre: "Les classiques des mots croisés",
        liste: [
            {
                mot: "risée",
                definition: "Petite brise passagère",
                ordre: [0, 1, 4,3,2]
            },
            {
                mot: "snob",
                definition: "Fait des manières",
                ordre: [0, 3, 2, 1]
            },
            {
                mot: "vahiné",
                definition: "Femme de Tahiti",
                ordre: [2, 0, 4, 5, 1, 3]
            },
            {
                mot: "wapiti",
                definition: "Grand cerf d'Amérique du Nord",
                ordre: [0,2,5, 4, 1, 3]
            },
            {
                mot: "geôle",
                definition: "Ancien cachot",
                ordre: [0, 2, 4,3,1]
            }
        ]
    },
    {
        id: 48,
        titre: "Les classiques des mots croisés (facile)",
        liste: [
            {
                mot: "brelan",
                definition: "Réunion de trois cartes de même valeur au poker",
                ordre: [5,0, 1, 4,3,2]
            },
            {
                mot: "talc",
                definition: "Poudre blanche pour les fesses de bébé",
                ordre: [0, 3, 2, 1]
            },
            {
                mot: "tsar",
                definition: "Ancien souverain russe",
                ordre: [1, 0, 2, 3]
            },
            {
                mot: "vespa",
                definition: "Deux roues à l'italienne",
                ordre: [0,2, 4, 1, 3]
            },
            {
                mot: "zinc",
                definition: "Comptoir d'un débit de boisson",
                ordre: [0, 1,3,2]
            }
        ]
    },
    {
        id: 47,
        titre: "Les classiques des mots croisés",
        liste: [
            {
                mot: "risée",
                definition: "Petite brise passagère",
                ordre: [0, 1, 4,3,2]
            },
            {
                mot: "snob",
                definition: "Fait des manières",
                ordre: [0, 3, 2, 1]
            },
            {
                mot: "vahiné",
                definition: "Femme de Tahiti",
                ordre: [2, 0, 4, 5, 1, 3]
            },
            {
                mot: "wapiti",
                definition: "Grand cerf d'Amérique du Nord",
                ordre: [0,2,5, 4, 1, 3]
            },
            {
                mot: "geôle",
                definition: "Ancien cachot",
                ordre: [0, 2, 4,3,1]
            }
        ]
    },
    {
        id: 48,
        titre: "Les classiques des mots croisés (facile)",
        liste: [
            {
                mot: "brelan",
                definition: "Réunion de trois cartes de même valeur au poker",
                ordre: [5,0, 1, 4,3,2]
            },
            {
                mot: "talc",
                definition: "Poudre blanche pour les fesses de bébé",
                ordre: [0, 3, 2, 1]
            },
            {
                mot: "tsar",
                definition: "Ancien souverain russe",
                ordre: [1, 0, 2, 3]
            },
            {
                mot: "vespa",
                definition: "Deux roues à l'italienne",
                ordre: [0,2, 4, 1, 3]
            },
            {
                mot: "zinc",
                definition: "Comptoir d'un débit de boisson",
                ordre: [0, 1,3,2]
            }
        ]
    },
        {
            id: 52,
            titre: "Connais-tu les nouveaux mots du dictionnaire 2025 ?",
            liste: [  {
                    mot: "écoféminisme",
                    definition: "Courant de pensée qui établit un parallèle entre la domination des hommes sur les femmes et la surexploitation de la nature.",
                    ordre: [4,6,10,7,2,9,5,11,1,3,0,8]
                },
                {
                    mot: "mégabassine",
                    definition: "Immense réservoir d'eau à ciel ouvert utilisé pour l'irrigation",
                    ordre: [3,5,10,0,1,6,7,4,2,9,8]
                },
              
                {
                    mot: "masculinisme",
                    definition: "Courant venu des États-Unis, en opposition au féminisme, qui veut réaffirmer des valeurs masculines.",
                    ordre: [8,5,10,11,1,0,2,7,4,6,3,9]
                },
                {
                    mot: "tiktokeur",
                    definition: "Personne qui publie des vidéos sur l'application TikTok.",
                    ordre: [1,3,5,7,4,8,0,6,2]
                },
                {
                    mot: "kombucha",
                    definition: "Boisson à base de thé, sucrée et fermentée grâce à des levures.",
                    ordre: [7,6,1,5,2,0,3,4]
                }
            ]
        }
        
    
    ];

export default donneesMots;
